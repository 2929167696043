<template>
    <div class="not-supported">
        <div class="mb-3">
            <icon
                size="40"
                :color="colors.yellow"
                name="indicator-warning" />
        </div>
        <h2 class="mb-2">
            {{ title }}
        </h2>
        <slot />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import colors from '@/helpers/colors';

export default {
    name: 'AdPlaceholder',
    components: {
        Icon
    },
    props: {
        title: {
            type: String,
            required: false,
            default: 'Unsupported Aspect Ratio'
        },
    },
    data() {
        return {
            colors
        };
    },
};
</script>

<style lang="scss" scoped>
.not-supported {
    min-height: 751px;
    padding: 80px 30px 30px 30px;
    text-align: center;
}
</style>
