<template>
    <div
        class="preview-container"
        @mouseover="showPlayButtons"
        @mouseleave="hidePlayButtons">
        <div
            v-if="!notSupported"
            class="preview-ui">
            <div class="top-elements">
                <div class="top-info">
                    <img
                        v-if="pageLogo"
                        class="logo"
                        :src="pageLogo">
                    <div
                        v-else
                        class="placeholder-logo">
                        YOUR
                        LOGO
                    </div>
                    <div class="top-desc">
                        <div class="top-title">
                            {{ pageName }}
                        </div>
                        <div class="top-sponsored">
                            Sponsored
                        </div>
                    </div>
                </div>
                <div class="top-dots">
                    <icon
                        class="top-dot"
                        size="9"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="9"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="9"
                        color="black"
                        name="circle" />
                </div>
            </div>
            <div class="instagram-feed-carousel">
                <carousel
                    :per-page="1"
                    :mouse-drag="true"
                    :navigation-enabled="false"
                    :pagination-enabled="true">
                    <slide
                        v-for="(slide, index) in creatives"
                        :key="index">
                        <div class="block">
                            <div
                                class="image-holder"
                                :class="{'platform-styles': platformStyles}">
                                <div
                                    v-if="controlsActive && slide.media_type === 'VIDEO' ||
                                        controlsActive && slide.assets[0].asset_type.name === 'video'"
                                    :ref="'video-icon'+index"
                                    class="play-wrapper"
                                    @click="playVideo(index)">
                                    <div class="play">
                                        <icon
                                            v-if="!hidePlayIcon"
                                            size="40"
                                            color="white"
                                            name="audio-play" />
                                        <div v-else>
                                            <div class="previews-pause-bar" />
                                            <div class="previews-pause-bar" />
                                        </div>
                                    </div>
                                </div>
                                <video
                                    v-if="slide.media_type === 'VIDEO' ||
                                        slide.assets[0].asset_type.name === 'video'"
                                    :key="slide.media_url"
                                    :ref="'video'+index"
                                    class="preview-video"
                                    muted
                                    loop>
                                    <source
                                        :src="url(index)"
                                        type="video/mp4">
                                </video>
                                <img
                                    v-else
                                    :key="slide.media_url"
                                    class="preview-image"
                                    :src="url(index)">
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="learn">
                <a
                    :href="ctaLink"
                    target="_blank">{{ ctaText }}</a>
            </div>
            <div class="social-icons">
                <img
                    src="/img/instagram-icons.png"
                    alt="icons">
            </div>
            <div class="primary-text">
                <div
                    ref="primary"
                    style="line-height:21px">
                    <span class="description-title">{{ pageName }}</span>
                    {{ primary }}
                    <span
                        v-if="seeMore"
                        class="seemore"
                        @click="openPrimary">...more</span>
                </div>
            </div>
        </div>
        <not-supported-placeholder v-else>
            <p>
                The aspect ratio of your media is not supported for this placement.
                Please upload media that is 2:3 or shorter to utilize this placement.
            </p>
        </not-supported-placeholder>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters, mapState } from 'vuex';
import NotSupportedPlaceholder from '@/components/globals/AdPreview/NotSupportedPlaceholder';
import { getImageFraction, getVideoFraction } from '@/helpers/getMediaFraction';
import { IMAGE_ASSET_TYPE } from '@/components/ad-studio/store/constants';

export default {
    components: {
        Icon,
        Carousel,
        Slide,
        NotSupportedPlaceholder
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true
        },
    },
    data() {
        return {
            platformStyles: false,
            notSupported: false,
            hidePlayIcon: false,
            controlsActive: true,
            defaultLogoUrl: 'https://www.facebook.com/images/fb_icon_325x325.png',
            seeMore: false,
            primary: '',
            fullPrimary: ''
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters(['dealerPrimaryLogo']),
        pageName() {
            return this.profileName || this.currentDealer.name || this.adData?.platform_specific_fields?.snapchat_page?.name;
        },
        pageLogo() {
            return this.profileLogo || this.dealerPrimaryLogo?.url || this.defaultLogoUrl;
        },
        profileName() {
            return this.adData?.platform_specific_fields?.profile?.name;
        },
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        creatives() {
            return this.adData?.creatives ?? [];
        },
        firstAsset() {
            return this.creatives[0]?.assets?.[0] ?? {};
        },
        assetType() {
            return (this.creatives[0]?.media_type || this.firstAsset?.asset_type?.name)?.toUpperCase();
        },
        ctaLink() {
            return this.adData?.cta_link || this.creatives?.cta_link;
        },
        ctaText() {
            return this.adData?.cta_text || this.creatives?.cta_text;
        },
        url() {
            return index => this.creatives[index].media_url ?? this.creatives[index].assets[0].url;
        }
    },
    watch:{
        adData:{
            handler() {
                this.formatPrimaryText();
                if (this.adData.platform_controls_media_styles) {
                    this.initPlatformMediaStyles();
                }
            },
            deep: true
        }
    },
    mounted() {
        if (this.adData.platform_controls_media_styles) {
            this.initPlatformMediaStyles();
        }
        this.formatPrimaryText();
    },
    methods: {
        formatPrimaryText() {
            // detailed formatting of primary text like we have it in FB business manager
            this.seeMore = false;
            let rawPrimaryText = this.adData.primary_text;
            if (rawPrimaryText) {
                rawPrimaryText = rawPrimaryText.trim();
                this.primary = rawPrimaryText;
                this.fullPrimary = rawPrimaryText;
                const lines = rawPrimaryText.split(/\r\n|\r|\n/);
                this.checkLines(lines);
            }
        },
        openPrimary() {
            this.seeMore = false;
            this.primary = this.fullPrimary;
        },
        checkLines(lines) {
            this.$nextTick(function() {
                const primary = this.$refs.primary;
                const textHeight = primary.offsetHeight;
                const lineHeight = parseInt(primary.style.lineHeight);
                const textLines = textHeight / lineHeight;
                if (lines.length > 2) {
                    this.seeMore = true;
                    const shortenedText = [];
                    lines.forEach((line, index) => {
                        if (index < 2) {
                            shortenedText.push(line);
                        }
                    });
                    this.primary = shortenedText.join('\n');
                    this.primary = this.primary.trim();
                }
                if (lines[lines.length - 1] == '') {
                    lines.splice(lines.length - 1, 1);
                    this.checkLines(lines);
                    return;
                }
                if (textLines > 2) {
                    this.seeMore = true;
                    let lastLine = lines[lines.length - 1];
                    if (lastLine) {
                        const lineWords = lastLine.split(' ');
                        // eslint-disable-next-line no-unused-vars
                        const lastWord = lineWords.pop();
                        lines[lines.length - 1] = lineWords.join(' ');
                        const shortenedText = [];
                        lines.forEach(line => {
                            shortenedText.push(line);
                        });
                        this.primary = shortenedText.join('\n');
                        this.primary = this.primary.trim();
                        // must be recursive until we get desired length
                        setTimeout(() => {
                            this.checkLines(lines);
                        }, 0);
                    }
                }
            });
        },
        resetStyles() {
            this.platformStyles = false;
            this.notSupported = false;
        },
        async initPlatformMediaStyles() {
            this.resetStyles();
            this.platformStyles = true;
            const fraction = this.assetType.toLowerCase() === IMAGE_ASSET_TYPE ? await getImageFraction(this.url(0)) : await getVideoFraction(this.url(0));
            // media specific rule
            if (fraction < 0.666) this.notSupported = true;
        },
        playVideo(i) {
            const videoRef = 'video'+i;
            if (this.hidePlayIcon) {
                this.$refs[videoRef][0].pause();
                this.hidePlayIcon = false;
            } else {
                this.$refs[videoRef][0].play();
                this.hidePlayIcon = true;
            }
        },
        showPlayButtons() {
            this.controlsActive = true;
        },
        hidePlayButtons() {
            if (this.hidePlayIcon) {
                this.controlsActive = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-container{
    position: relative;
    width: 500px;
    display: flex;
    flex-direction: column;
    background-color:$white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
    @media only screen and (max-width: 599px) {
        width: 375px;

    }
    @media only screen and (max-width: 400px) {
        width: 320px;

    }
}
.preview-ui{
    position: relative;
    display: flex;
    height:100%;
    flex-direction: column;
}
.top-elements{
    border-top: 1px solid #DDDDDD;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
}
.top-dots{
    margin-top:3px;
    white-space: nowrap;
}
.top-dot{
    display: inline-block;
    margin-left: 5px;
}
.logo{
    width:40px;
    height:40px;
    border-radius: 50%;
}
.placeholder-logo{
    width:40px;
    height:40px;
    border-radius: 50%;
    border:1px solid white;
    color:white;
    background:black;
    text-align: center;
    font-weight:900;
    font-size: 9px;
    padding-top:8px;
    line-height: 11px;
}
.top-info{
    display: flex;
}
.top-desc{
    margin-left:8px;
}
.top-title{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    margin-top:2px;
}
.top-sponsored{
    font-size: 13px;
    color: $gray;
}
.primary-text{
    font-size: 14px;
    padding: 15px;
    color:black;
    overflow: hidden;
    position: relative;
    white-space: pre-wrap;
    margin-bottom: 45px;
    border-bottom: 1px solid #DDDDDD;
}
.seemore{
    background: white;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    color: $gray;
    &:hover{
        text-decoration: underline;
    }
}
.description-title{
    font-weight: 700;
    margin-right: 10px;
}
.block{
    width: 100%;
    box-sizing: border-box;

}
.image-holder{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-height: 500px;
    background:black;
    img, video{
        display: inline-block;
        max-width: 100%;
        max-height: 500px;
        height: auto;
        vertical-align: top;
        @media only screen and (max-width: 599px) {
        max-height: 375px;
        }
        @media only screen and (max-width: 400px) {
        max-height: 320px;
        }
    }
    @media only screen and (max-width: 599px) {
       min-height: 375px;
    }
    @media only screen and (max-width: 400px) {
       min-height: 320px;
    }
}
.ad-studio-previews .preview .instagram-feed-carousel .image-holder.platform-styles {
    min-height: 100px;
    img, video {
        max-height: none;
    }
}
.learn{
    padding: 13px 16px;
    border-bottom: 1px solid #F1F1F2;
    a{
        color:black;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
    }
}

.social-icons{
    padding: 16px 16px 0 16px;
    img{
        width: 112px;
        height: auto;
        margin-top:5px;
        @media only screen and (max-width: 599px) {
            width: 90px;
            margin-left: 0;
        }
    }
}

.play-wrapper{
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    cursor: pointer;
    z-index: 3;
    .play{
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-20px;
        margin-left:-20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
    &:after{
        content:'';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top:50%;
        left:50%;
        margin-top:-44px;
        margin-left:-45px;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        z-index: 1;
    }
}

</style>

<style lang="scss">
.instagram-feed-carousel{
    .VueCarousel-pagination{
        position: absolute;
        bottom:-110px;
    }
    .VueCarousel-dot{
        padding: 3px !important;
        width: 12px !important;
        height: 12px !important;
        background-color: #D8D8D8 !important;
    }
    .VueCarousel-dot--active{
        background-color: #6EADFF !important;
    }
}

</style>
